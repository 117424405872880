$(function() {
    /*
     * Constructor, if you so will
     */
    rmgMainApp.init();

    /*
     * Dom-Ready Handlers
     */
    rmgMainApp.dropdownDirection();
    rmgMainApp.erroneousCellsToRows();
    rmgMainApp.expDateDisplay();
    rmgMainApp.foldOutMenuEventHandlers();
    rmgMainApp.foldOutMenuSetWidth();
    rmgMainApp.footerColumns();
    rmgMainApp.handleExternalWebViewLinks();
    rmgMainApp.inlinifySvg();
    rmgMainApp.loginVerticalCenter();
    rmgMainApp.logoutCounterInit();
    rmgMainApp.placeholdersInit();
    rmgMainApp.prettyRadioButtons();
    rmgMainApp.stickyFooterSetHeights();
    rmgMainApp.tableFlippedLayout();
    rmgMainApp.tabularDataLayout();

    /*
     * onLoad Handlers
     */
    $(window).on('load', function() {
        rmgMainApp.dropdownDirection();
        rmgMainApp.inlinifySvg();
        rmgMainApp.stickyFooterSetHeights();
    });

    /*
     * Resize Handlers
     */
    $(window).resize(_.throttle(function() {
        rmgMainApp.resizeHandler();
    }, 100, { 'leading': false, 'trailing': true }));
    $(window).on('orientationchange', _.throttle(function() {
        rmgMainApp.resizeHandler();
    }, 100, { 'leading': true, 'trailing': false }));

    /*
     * Scroll Handlers
     */
    $(window).on('scroll', _.throttle(function() {
        rmgMainApp.headerCollapse();
        rmgMainApp.modalsVerticalCenter();
        rmgMainApp.dropdownDirection();
    }, 100, { 'leading': false, 'trailing': true }));

    /*
     * Custom Events
     */
    window.eventCounterReset = document.createEvent('Event');
    window.eventCounterReset.initEvent('counterReset', true, false);

    /*
     * Miscellaneous & Custom Event Handlers
     */
    $(window).on('counterReset', function() {
        rmgMainApp.logoutCounterReset(true);
    });
    $(document.body).on('hidden.bs.modal', '#modal-logged-out', function(e) {
        window.location.reload();
    });
    $(document.body).on('show.bs.modal', '.modal.modal-vcenter', function(e) {
        rmgMainApp.modalsVerticalCenter($(this));
    });
});

function rmgHeaderCollapseDebounceCallback() {
    var $headerContainer = $('div.container-header'),
        offset = $(window).scrollTop(),
        headerHeight = $headerContainer.outerHeight(),
        isPastHeader = headerHeight < offset;

    if ($headerContainer.find('.in-use').length === 0 && isPastHeader) {
        $headerContainer.addClass('collapsed');
    }
}

function rmgFoldOutMenuRestoreVisibilityDebounceCallback() {
    rmgMainApp.$menuLeft.css({'visibility': ''});
    rmgMainApp.$menuRight.css({'visibility': ''});
    rmgMainApp.$menuLanguage.css({'visibility': ''});
}

function formatLogoutTime(newTime) {
    var newSeconds = '0'+(newTime%60);
    return (Math.floor(newTime/60))+':'+newSeconds.substring(newSeconds.length-2);
}

var rmgMainApp = {
    /* Cached Selectors */
    $body: null,
    $buttonHamburger: null,
    $buttonUserMenu: null,
    $dropdownButtons: null,
    $headerContainer: null,
    $headerCounter: null,
    $headerCounterTimeLeft: null,
    $headerLanguageSelector: null,
    $menuLeft: null,
    $menuRight: null,
    $menuLanguage: null,
    $footerContainer: null,
    $footerNotice: null,
    $footerLinks: null,
    $footerLinksList1: null,
    $footerLinksList2: null,
    $tableFlipped: null,
    $tabularData: null,

    /* Properties */
    countdownIntervalId: 0,
    isWebView: false,
    lastCounterReset: 0,
    lastScrollPosition: 0,
    oldWidth: 0,
    tabularDataIsShown: true,
    touchIsTap: false,
    touchstartX: -9999,
    touchstartY: -9999,
    viewportHeight: 0,
    viewportWidth: 0,

    /* Initialize / Cache Selectors */
    init: function() {
        this.$body = $('body');
        this.$buttonHamburger = $('button#btn-hamburger');
        this.$buttonUserMenu = $('button#btn-user-menu');
        this.$dropdownButtons = $('.dropdown-toggle');
        this.$headerContainer = $('div.container-header');
        this.$headerCounter = $('div.logged-in-counter');
        this.$headerCounterTimeLeft = $('div.logged-in-counter span.time-left');
        this.$headerLanguageSelector = $('div#language-selector');
        this.$menuLeft = $('div.fold-out-menu-left');
        this.$menuRight = $('div.fold-out-menu-right');
        this.$menuLanguage = $('ul#language-selector-list');
        this.$footerContainer = $('div#footer-container');
        this.$footerNotice = $('div#footer-copyright-notice');
        this.$footerLinks = $('div#footer-links');
        this.$footerLinksList1 = $('ul#footer-links-1');
        this.$footerLinksList2 = $('ul#footer-links-2');

        this.isWebView = $('#main-wrap').hasClass('main-wrap-webview');

        this.viewportDimensionsUpdate();
        this.initTableFlipped();
        this.initTabularData();
    },

    initTableFlipped: function() {
        var self = this;

        this.$tableFlipped = {
            count: 0,
            md: [],
            sm: [],
            xs: [],
            xxs: []
        };

        $('table.table-flip').each(function() {
            self.$tableFlipped.count += 1;

            if ($(this).hasClass('table-flip-md')) {
                self.$tableFlipped.md.push($(this));
            } else if ($(this).hasClass('table-flip-sm')) {
                self.$tableFlipped.sm.push($(this));
            } else if ($(this).hasClass('table-flip-xs')) {
                self.$tableFlipped.xs.push($(this));
            } else if ($(this).hasClass('table-flip-xxs')) {
                self.$tableFlipped.xxs.push($(this));
            }
        });
    },

    initTabularData: function() {
        var self = this;

        this.$tabularData = {
            count: 0,
            all: [],
            single: [],
            grouped: []
        };

        $('div.tabular-data').each(function() {
            self.$tabularData.count += 1;
            self.$tabularData.all.push($(this));

            var $siblings = $(this).closest('.row').find('div.tabular-data');

            if ($siblings.length > 1) {
                if (! $siblings.first().hasClass('initialized')) {
                    $siblings.addClass('initialized');
                    self.$tabularData.grouped.push($siblings);
                }
            } else {
                self.$tabularData.single.push($(this));
            }
        });
    },

    dropdownDirection: function() {
        this.$dropdownButtons.each(function() {
            var $this = $(this),
                scrollTop = $(window).scrollTop(),
                viewPortTop = $this.offset().top - scrollTop,
                viewPortBottom = $(window).height() - (viewPortTop + $this.outerHeight()),
                $parent = $this.parent('div');

            if (viewPortTop > viewPortBottom) {
                if ($parent.hasClass('dropdown')) {
                    $parent.removeClass('dropdown').addClass('dropup');
                }
            } else {
                if ($parent.hasClass('dropup')) {
                    $parent.removeClass('dropup').addClass('dropdown');
                }
            }
        });
    },

    erroneousCellsToRows: function() {
        /* The backend sets a class on a table cell based on cell value, in the UI we want to highlight the respective row(s) */
        $('td.execution-failed').closest('tr').addClass('has-error');
    },

    expDateDisplay: function() {
        var $validThruCardField = $('.validThruCardField');

        if ($validThruCardField.length) {
            var monthsToExpirationDate = 37,
                newExpiration = new Date();
            newExpiration.setMonth(newExpiration.getMonth() + monthsToExpirationDate);
            $validThruCardField.text((rmgUtils.pad2(newExpiration.getMonth())) + '/' + (newExpiration.getFullYear() + '').substring(2, 4));
        }
    },

    foldOutMenuEventHandlers: function() {
        /*
         * 1. Opening (and closing) the menus
         *
         * 1.1 (Used) input device: mouse / trackpad
         */
        //if (! (this.$body.hasClass('order') || this.$body.hasClass('login'))) {
        //    this.$buttonUserMenu.mouseenter(function(event) {
        //        this.foldOutMenuHandlerMouseenter(event, this.$buttonUserMenu, this.$menuRight);
        //    }.bind(this)).mouseleave(function(event) {
        //        this.foldOutMenuHandlerMouseleave(event, this.$buttonUserMenu, this.$menuRight);
        //    }.bind(this));
        //    this.$buttonHamburger.mouseenter(function(event) {
        //        this.foldOutMenuHandlerMouseenter(event, this.$buttonHamburger, this.$menuLeft);
        //    }.bind(this)).mouseleave(function(event) {
        //        this.foldOutMenuHandlerMouseleave(event, this.$buttonHamburger, this.$menuLeft);
        //    }.bind(this));
        //}
        //this.$headerLanguageSelector.mouseenter(function(event) {
        //    this.foldOutMenuHandlerMouseenter(event, this.$headerLanguageSelector, this.$menuLanguage);
        //}.bind(this)).mouseleave(function(event) {
        //    this.foldOutMenuHandlerMouseleave(event, this.$headerLanguageSelector, this.$menuLanguage);
        //}.bind(this));
        /*
         * 1.2 (Used) input device: touch screen & browser with touchevents API
         */
        if (! (this.$body.hasClass('order') || this.$body.hasClass('login'))) {
            this.$buttonUserMenu.on('touchstart', function(event) {
                this.foldOutMenuHandlerTouchstart(event);
            }.bind(this)).on('touchend', function(event) {
                this.foldOutMenuHandlerTouchend(event, this.$buttonUserMenu, 'div.fold-out-menu-right');
            }.bind(this));
            this.$buttonHamburger.on('touchstart', function(event) {
                this.foldOutMenuHandlerTouchstart(event);
            }.bind(this)).on('touchend', function(event) {
                this.foldOutMenuHandlerTouchend(event, this.$buttonHamburger, 'div.fold-out-menu-left');
            }.bind(this));
        }
        this.$headerLanguageSelector.on('touchstart', function(event) {
            this.foldOutMenuHandlerTouchstart(event);
        }.bind(this)).on('touchend', function(event) {
            this.foldOutMenuHandlerTouchend(event, this.$headerLanguageSelector, 'ul#language-selector-list');
        }.bind(this));
        /*
         * 1.2.1 Clean-up
         */
        $(document.body).on('touchend', function() {
            this.touchstartX = -9999;
            this.touchstartY = -9999;
        }.bind(this));
        /*
         * 1.3 (Used) input device: keyboard or something obscure / neither mouse- nor touchevents / fallback
         */
        if (! (this.$body.hasClass('order') || this.$body.hasClass('login'))) {
            this.$buttonUserMenu.click(function(event) {
                this.foldOutMenuHandlerClick(event, this.$buttonUserMenu, 'div.fold-out-menu-right');
            }.bind(this));
            this.$buttonHamburger.click(function(event) {
                this.foldOutMenuHandlerClick(event, this.$buttonHamburger, 'div.fold-out-menu-left');
            }.bind(this));
        }
        this.$headerLanguageSelector.click(function(event) {
            this.foldOutMenuHandlerClick(event, this.$headerLanguageSelector, 'ul#language-selector-list');
        }.bind(this));
        /*
         * 2. Closing the menus on interactions outside of them (or in the respective other menu)
         *
         * Since we're only removing classes (closing), possibly firing handlers twice (touchend, click) does not have negative side effects
         */
        $(document.body).on('click touchstart', function(event) {
            var $target = $(event.target);
            if ($target.closest('button#btn-user-menu').length === 0 && $target.closest('div.fold-out-menu-right').length === 0) {
                this.foldOutMenuToggleState(this.$buttonUserMenu, this.$menuRight, true, null);
            }
            if ($target.closest('button#btn-hamburger').length === 0 && $target.closest('div.fold-out-menu-left').length === 0) {
                this.foldOutMenuToggleState(this.$buttonHamburger, this.$menuLeft, true, null);
            }
            if ($target.closest('div#language-selector').length === 0 && $target.closest('ul#language-selector-list').length === 0) {
                this.foldOutMenuToggleState(this.$headerLanguageSelector, this.$menuLanguage, true, null);
            }
        }.bind(this));
    },

    foldOutMenuHandlerClick: function(event, $button, menu) {
        if (! $button.hasClass('in-use-mouse')) {
            /* Handle click if touchend handler did not fire */
            if (! this.touchIsTap) {
                var $target = $(event.target);
                if ($target.closest(menu).length === 0) {
                    if ($button.hasClass('in-use')) {
                        this.foldOutMenuToggleState($button, $(menu), true, null);
                    } else {
                        this.foldOutMenuToggleState($button, $(menu), false, 'click');
                    }
                }
            /* Otherwise, unset the touch handler firing flag */
            } else {
                this.touchIsTapUnset();
            }
            if ($target.closest(menu).length === 0) {
                event.preventDefault();
            }
        }
    },

    foldOutMenuHandlerMouseenter: function(event, $button, $menu) {
        if (! $menu.is('ul#language-selector-list') || this.viewportWidth > 479) {
            if (! $button.hasClass('in-use-touch') && ! $button.hasClass('in-use-click')) {
                this.foldOutMenuToggleState($button, $menu, false, 'mouse');
            }
        }
    },

    foldOutMenuHandlerMouseleave: function(event, $button, $menu) {
        if ($button.hasClass('in-use-mouse')) {
            this.foldOutMenuToggleState($button, $menu, true, null);
        }
    },

    foldOutMenuHandlerTouchstart: function(event) {
        this.touchstartX = rmgUtils.getTouchCoordinates(event, 'X');
        this.touchstartY = rmgUtils.getTouchCoordinates(event, 'Y');
    },

    foldOutMenuHandlerTouchend: function(event, $button, menu) {
        if (! $button.hasClass('in-use-mouse')) {
            var $target = $(event.target);
            if ($target.closest(menu).length === 0) {
                /* make sure this was a tap and not a swipe, scroll, or some other gesture (max. 20px movement from touchstart) */
                if (Math.abs(rmgUtils.getTouchCoordinates(event, 'X') - this.touchstartX) < 20 && Math.abs(rmgUtils.getTouchCoordinates(event, 'Y') - this.touchstartY) < 20) {
                    if ($button.hasClass('in-use')) {
                        this.foldOutMenuToggleState($button, $(menu), true, null);
                    } else {
                        this.foldOutMenuToggleState($button, $(menu), false, 'touch');
                    }
                    /* prevent emulated mouse events in mobile browsers (i.e. prevent "ghost clicks" - executing the handler twice) */
                    event.preventDefault();
                    /* fallback for Android stock browsers & Windows Phone, which do not respect preventDefault in this case */
                    this.touchIsTapSet();
                }
            }
        }
    },

    foldOutMenuRestoreVisibility: _.bind(_.debounce(rmgFoldOutMenuRestoreVisibilityDebounceCallback, 500), this),

    foldOutMenuSetWidth: function() {
        this.$menuLeft.css({'visibility': 'hidden'});
        this.$menuRight.css({'visibility': 'hidden'});
        this.$menuLanguage.css({'visibility': 'hidden'});

        if (this.$body.hasClass('order')) {
            return;
        }

        var menus = {
                left: {
                    height: 0,
                    width: 0,
                    $menu: this.$menuLeft
                },
                right: {
                    height: 0,
                    width: 0,
                    btnWidth: 0,
                    $menu: this.$menuRight
                },
                language: {
                    height: 0,
                    width: 0,
                    $menu: this.$menuLanguage
                }
            },
            stylesheetContents = '';

        $('style#fold-out-transitions').remove();
        $('style#fold-out-style').remove();

        if (this.$body.hasClass('login')) {
            var $menu = this.$menuLanguage,
                width = $menu.outerWidth(),
                btnWidth = $menu.closest('div#language-selector').find('div#language-selector-active-language').outerWidth();

            width = (width > btnWidth ? width : btnWidth) + 1;

            stylesheetContents += '\
                body.login div#language-selector-active-language {\
                    width: '+width+'px;\
                }\
                body.login div#language-selector ul#language-selector-list,\
                html.csstransforms3d body.login div#language-selector ul#language-selector-list,\
                html.csstransforms3d body.login div#language-selector ul#language-selector-list.open {\
                    right: -'+width+'px;\
                    width: '+width+'px;\
                }\
                html.csstransforms3d body.login div#language-selector ul#language-selector-list.open {\
                    transform: translate3d(-'+width+'px, 0, 0);\
                    -webkit-transform: translate3d(-'+width+'px, 0, 0);\
                    -ms-transform: translate3d(-'+width+'px, 0, 0);\
                }\
                body.login div#language-selector ul#language-selector-list li,\
                body.login div#language-selector ul#language-selector-list li a {\
                    width: 100%;\
                }';

        } else {

            _.forEach(menus, function(menuProps, key) {
                menus[key].height = menuProps.$menu.outerHeight();
                menus[key].width = menuProps.$menu.outerWidth();
            });
            menus['right'].btnWidth = this.$buttonUserMenu.outerWidth();

            if (this.viewportWidth > 1199) {
                stylesheetContents += '\
                    @media screen and (min-width: 1200px) {\
                        div.fold-out-menu,\
                        html.csstransforms3d div.fold-out-menu,\
                        html.csstransforms3d div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu.open,\
                        html.csstransforms3d div.fold-out-menu-left.open,\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            top: 59px;\
                        }\
                        div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            right: -'+menus.right.btnWidth+'px;\
                            width: '+menus.right.btnWidth+'px;\
                        }\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            transform: translate3d(-'+menus.right.btnWidth+'px, 0, 0);\
                            -webkit-transform: translate3d(-'+menus.right.btnWidth+'px, 0, 0);\
                            -ms-transform: translate3d(-'+menus.right.btnWidth+'px, 0, 0);\
                        }\
                    }';
            } else {
                stylesheetContents += '\
                    div.fold-out-menu-right,\
                    html.csstransforms3d div.fold-out-menu-right,\
                    html.csstransforms3d div.fold-out-menu-right.open {\
                        top: -'+(menus.right.height - 49)+'px;\
                        height: '+menus.right.height+'px;\
                    }\
                    html.csstransforms3d div.fold-out-menu-right.open {\
                        transform: translate3d(0, '+menus.right.height+'px, 0);\
                        -webkit-transform: translate3d(0, '+menus.right.height+'px, 0);\
                        -ms-transform: translate3d(0, '+menus.right.height+'px, 0);\
                    }\
                    div.fold-out-menu-left,\
                    html.csstransforms3d div.fold-out-menu-left,\
                    html.csstransforms3d div.fold-out-menu-left.open {\
                        top: -'+(menus.left.height - 49)+'px;\
                        height: '+menus.left.height+'px;\
                    }\
                    html.csstransforms3d div.fold-out-menu-left.open {\
                        transform: translate3d(0, '+menus.left.height+'px, 0);\
                        -webkit-transform: translate3d(0, '+menus.left.height+'px, 0);\
                        -ms-transform: translate3d(0, '+menus.left.height+'px, 0);\
                    }\
                    div#language-selector ul#language-selector-list,\
                    html.csstransforms3d div#language-selector ul#language-selector-list,\
                    html.csstransforms3d div#language-selector ul#language-selector-list.open {\
                        top: -'+menus.language.height+'px;\
                        height: '+menus.language.height+'px;\
                    }\
                    html.csstransforms3d div#language-selector ul#language-selector-list.open {\
                        transform: translate3d(0, '+menus.language.height+'px, 0);\
                        -webkit-transform: translate3d(0, '+menus.language.height+'px, 0);\
                        -ms-transform: translate3d(0, '+menus.language.height+'px, 0);\
                    }\
                    @media screen and (min-width: 360px) {\
                        div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            top: -'+(menus.right.height - 59)+'px;\
                        }\
                        div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-left.open {\
                            top: -'+(menus.left.height - 59)+'px;\
                        }\
                    }\
                    @media screen and (min-width: 480px) {\
                        div.fold-out-menu,\
                        html.csstransforms3d div.fold-out-menu,\
                        html.csstransforms3d div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu.open,\
                        html.csstransforms3d div.fold-out-menu-left.open,\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            top: 59px;\
                        }\
                        div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right,\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            right: -'+menus.right.width+'px;\
                            width: '+menus.right.width+'px;\
                        }\
                        html.csstransforms3d div.fold-out-menu-right.open {\
                            transform: translate3d(-'+menus.right.width+'px, 0, 0);\
                            -webkit-transform: translate3d(-'+menus.right.width+'px, 0, 0);\
                            -ms-transform: translate3d(-'+menus.right.width+'px, 0, 0);\
                        }\
                        div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-left,\
                        html.csstransforms3d div.fold-out-menu-left.open {\
                            left: -'+menus.left.width+'px;\
                            width: '+menus.left.width+'px;\
                        }\
                        html.csstransforms3d div.fold-out-menu-left.open {\
                            transform: translate3d('+menus.left.width+'px, 0, 0);\
                            -webkit-transform: translate3d('+menus.left.width+'px, 0, 0);\
                            -ms-transform: translate3d('+menus.left.width+'px, 0, 0);\
                        }\
                    }';
            }
            stylesheetContents += '\
                @media screen and (min-width: 480px) {\
                    div#language-selector ul#language-selector-list {\
                        top: -'+menus.language.height+'px;\
                    }\
                    div#language-selector ul#language-selector-list.open,\
                    html.csstransforms3d div#language-selector ul#language-selector-list,\
                    html.csstransforms3d div#language-selector ul#language-selector-list.open {\
                        top: -5px;\
                        height: '+menus.language.height+'px;\
                        width: '+menus.language.width+'px;\
                    }\
                    html.csstransforms3d div#language-selector ul#language-selector-list {\
                        transform: translate3d('+Math.ceil(menus.language.width / 2)+'px, -'+Math.ceil(menus.language.height / 2)+'px, 0) scale3d(0, 0, 1);\
                        -webkit-transform: translate3d('+Math.ceil(menus.language.width / 2)+'px, -'+Math.ceil(menus.language.height / 2)+'px, 0) scale3d(0, 0, 1);\
                        -ms-transform: translate3d('+Math.ceil(menus.language.width / 2)+'px, -'+Math.ceil(menus.language.height / 2)+'px, 0) scale3d(0, 0, 1);\
                    }\
                    html.csstransforms3d div#language-selector ul#language-selector-list.open {\
                        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);\
                        -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);\
                        -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);\
                    }\
                }';
        }

        $('<style id="fold-out-style">')
           .prop('type', 'text/css')
           .html(stylesheetContents)
           .appendTo('head');

        this.foldOutMenuSetTransitions();

        this.foldOutMenuRestoreVisibility();
    },

    foldOutMenuSetTransitions: function() {
        $('<style id="fold-out-transitions">')
           .prop('type', 'text/css')
           .html('\
                html.csstransforms3d div.fold-out-menu,\
                html.csstransforms3d div.fold-out-menu-right,\
                html.csstransforms3d div.fold-out-menu-left,\
                html.csstransforms3d div#language-selector ul#language-selector-list {\
                    -webkit-transition: transform .3s ease-in-out;\
                    -moz-transition: transform .3s ease-in-out;\
                    -ms-transition: transform .3s ease-in-out;\
                    -o-transition: transform .3s ease-in-out;\
                    transition: transform .3s ease-in-out;\
            }')
           .appendTo('head');
    },

    foldOutMenuToggleState: function($button, $menu, isOpen, inputMethod) {
        if (isOpen) {
            $button.removeClass('in-use').removeClass('in-use-mouse').removeClass('in-use-touch').removeClass('in-use-click');
            $menu.removeClass('open');
            this.headerCollapseDebounce();
        } else {
            $button.addClass('in-use').addClass('in-use-'+inputMethod);
            $menu.addClass('open');
        }
    },

    footerColumns: function() {
        var stylesheetContent = '',
            width1 = 0,
            width2 = 0,
            widthLinks = 0,
            widthLinksStacked = 0,
            $children1 = this.$footerLinksList1.children('li'),
            $children2 = this.$footerLinksList2.children('li');

        if ($children1.length > 0) {
            $children1.each(function() {
                var reqWidth = $(this).is('#footer-social-channels') ? ($(this).find('a').first().outerWidth() * 3) : $(this).find('a').first().outerWidth();
                width1 = reqWidth > width1 ? reqWidth : width1;
            });
        }


        if ($children2.length > 0) {
            $children2.each(function() {
                var reqWidth = $(this).is('#footer-social-channels') ? ($(this).find('a').first().outerWidth() * 3) : $(this).find('a').first().outerWidth();
                width2 = reqWidth > width2 ? reqWidth : width2;
            });
        }

        if (width1) {
            width1 += 42;
        } else {
            this.$footerLinksList1.hide(0);
        }

        if (width2) {
            width2 += 42;
        } else {
            this.$footerLinksList2.hide(0);
        }

        widthLinks = width1 + width2;
        widthLinksStacked = width1 > width2 ? width1 : width2;

        if ($children2.length + $children1.length > 3) {
            stylesheetContent = '\
                    @media screen and (min-width: 600px)\
                    {\
                        #footer-links {\
                            width: '+widthLinksStacked+'px;\
                        }\
                        #footer-copyright-notice {\
                            margin-left: '+widthLinksStacked+'px;\
                        }\
                    }\
                    @media screen and (min-width: 920px)\
                    {\
                        #footer-links {\
                            width: '+widthLinks+'px;\
                        }\
                        #footer-copyright-notice {\
                            margin-left: '+widthLinks+'px;\
                        }\
                        #footer-links ul#footer-links-1 {\
                            width: '+width1+'px;\
                        }\
                        #footer-links ul#footer-links-2 {\
                            width: '+width2+'px;\
                        }\
                    }';
        } else {
            stylesheetContent = '\
                    @media screen and (min-width: 600px)\
                    {\
                        #footer-links {\
                            width: '+widthLinksStacked+'px;\
                        }\
                        #footer-copyright-notice {\
                            margin-left: '+widthLinksStacked+'px;\
                        }\
                    }\
                    @media screen and (min-width: 920px)\
                    {\
                        #footer-links {\
                            width: '+widthLinksStacked+'px;\
                        }\
                        #footer-copyright-notice {\
                            margin-left: '+widthLinksStacked+'px;\
                        }\
                        #footer-links ul {\
                            width: 100%;\
                        }\
                    }';
        }

        $('<style id="footer-links-style">')
            .prop('type', 'text/css')
            .html(stylesheetContent)
            .appendTo('head');
    },

    handleExternalWebViewLinks: function()
    {
        if (this.isWebView)
        {
            $('a.webview-external-link').click(function(event)
            {
                event.preventDefault();
                event.stopPropagation();
                var href = $(this).attr('href'),
                    url = '';
                if (href.substring(0,4) === 'http') {
                    url = href;
                } else {
                    if (href.substring(0,1) === '/') {
                        url = window.location.origin+href;
                    } else {
                        url = window.location.origin+'/'+href;
                    }
                }
                var jsonBody = JSON.stringify({
                    'actionId': 'external.redirect',
                    'processStopped': false, // whether the WebView should close
                    'errorMessage': '', // employed by the backend, we don't want to send an error here
                    'data': {
                        'url': url
                    }
                });
                WebViewBridge.send(jsonBody);
            });
        }
    },

    handleThresholds: function() {
        var newWidth = this.viewportWidth,
            thresholdPassedFoldOut = false,
            thresholdPassedTabularData = false,
            thresholdPassedTooltip = false;

        if (this.oldWidth) {
            if (this.oldWidth > 1199 && newWidth < 1200) {
                thresholdPassedFoldOut = true;
                thresholdPassedTabularData = true;
                thresholdPassedTooltip = true;
            } else if (this.oldWidth < 1200 && newWidth > 1199) {
                thresholdPassedFoldOut = true;
                thresholdPassedTabularData = true;
                thresholdPassedTooltip = true;
            } else if (this.oldWidth > 1023 && newWidth < 1024) {
                thresholdPassedFoldOut = true;
                thresholdPassedTabularData = true;
            } else if (this.oldWidth < 1024 && newWidth > 1023) {
                thresholdPassedFoldOut = true;
                thresholdPassedTabularData = true;
            } else if (this.oldWidth > 991 && newWidth < 992) {
                thresholdPassedTabularData = true;
            } else if (this.oldWidth < 992 && newWidth > 991) {
                thresholdPassedTabularData = true;
            } else if (this.oldWidth < 768 && newWidth > 767) {
                thresholdPassedTabularData = true;
            } else if (this.oldWidth > 479 && newWidth < 480) {
                thresholdPassedFoldOut = true;
            } else if (this.oldWidth < 480 && newWidth > 479) {
                thresholdPassedFoldOut = true;
            }
        }
        if (newWidth < 768) {
            thresholdPassedTabularData = true;
        }

        if (thresholdPassedFoldOut) {
            this.foldOutMenuSetWidth();
        }
        if (thresholdPassedTabularData) {
            this.tabularDataLayout();
        }
        if (thresholdPassedTooltip) {
            this.tooltipPlacements(newWidth);
        }

        this.oldWidth = newWidth;
    },

    headerCollapse: function() {
        if (this.viewportHeight > 360 || (this.viewportHeight > 340 && this.viewportWidth > 480)) {
            var offset = $(window).scrollTop(),
                $headerContainer = this.$headerContainer,
                headerHeight = $headerContainer.outerHeight(),
                lastScrollPosition = this.lastScrollPosition,
                isPastHeader = headerHeight < offset,
                isDown = lastScrollPosition < offset;

            if (! isPastHeader) {
                $headerContainer.removeClass('collapsed');
            } else {
                if (isDown) {
                    $headerContainer.find('.open').removeClass('open');
                    $headerContainer.find('.in-use').removeClass('in-use').removeClass('in-use-click').removeClass('in-use-touch').removeClass('in-use-mouse');
                    $headerContainer.find(':focus').blur();
                    $headerContainer.addClass('collapsed');
                } else {
                    $headerContainer.removeClass('collapsed');
                    this.headerCollapseDebounce();
                }
            }

            this.lastScrollPosition = offset;
        }
    },

    headerCollapseDebounce: _.bind(_.debounce(rmgHeaderCollapseDebounceCallback, 3000), this),

    inlinifySvg: function() {
        $('object.svg-object').each(function() {
            var $obj = $(this),
                objClassNames = $obj.attr('class'),
                svgUrl = $obj.attr('data');

            $.get(svgUrl, function(data) {
                var $svg = jQuery(data).find('svg').removeAttr('xmlns:a');
                $obj.replaceWith(function() {
                    return($('<div>').attr('class', objClassNames).removeClass('svg-object').html($svg));
                });
            }, 'xml');
        });
    },

    loginVerticalCenter: function() {
        var $loginArea = $('body.login .content');
        if (! this.isWebView)
        {
            if ($loginArea && $loginArea.length === 1) {
                var areaHeight = $loginArea.outerHeight(),
                    footerHeight = this.$footerContainer.outerHeight(),
                    headerHeight = this.$headerContainer.outerHeight();

                $loginArea.css({'margin-top': 0});

                var areaOffset = $loginArea.offset().top,
                    areaContentOffset = areaOffset - headerHeight,
                    areaBottomSpace = this.viewportHeight - (areaHeight + areaContentOffset) - footerHeight;

                if (areaOffset < areaBottomSpace) {
                    $loginArea.css({'margin-top': (Math.floor((areaBottomSpace - areaOffset) / 2.8))});
                }
            }
        }
        if ($loginArea && $loginArea.length === 1)
        {
            $loginArea.addClass('js-shown');
        }
    },

    logoutCounterInit: function() {
        if (rmgUtils.hasStorage()) {
            localStorage.rmgLogoutCounterReset = new Date().getTime();
            this.lastCounterReset = localStorage.rmgLogoutCounterReset;
        }

        if(this.$headerCounter.length) {
            this.countdownIntervalId = window.setInterval(this.logoutCounterCountdown.bind(this), 1000);
            if (! $('#modal-logged-out').length) {
                $(document.body).append('<div class="modal fade modal-vcenter" id="modal-logged-out" tabindex="-1" role="dialog" aria-labelledby="logged-out-text">' +
                    '<div class="modal-dialog" role="document">' +
                        '<div class="modal-content">' +
                            '<div class="modal-body">' +
                                '<button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="'+i18n.gettext('Close')+'"></button>' +
                                '<p id="logged-out-text" class="mrg-btm-none">' +
                                    i18n.gettext('As you have been inactive for an extended amount of time, you have been logged out to keep your account secure.') +
                                    '<br /><br />' +
                                    i18n.gettext('Please log back in and we\'ll take you back to where you were.') +
                                '</p>' +
                                '<div style="overflow:hidden;">' +
                                    '<div class="float-end mrg-top-sm">' +
                                        '<button type="button" class="btn btn-xcard-main" data-bs-dismiss="modal">'+i18n.gettext('Close')+'</button>' +
                                    '</div>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>');
            }
        }
    },

    logoutCounterReset: function(triggerGlobally) {
        if (this.$headerCounter.length) {
            if (triggerGlobally && rmgUtils.hasStorage()) {
                localStorage.rmgLogoutCounterReset = new Date().getTime();
            }
            this.$headerCounterTimeLeft
                .removeClass('time-left-one-minute')
                .attr('data-seconds', ''+window._logoutTimeoutSeconds)
                .text(formatLogoutTime(window._logoutTimeoutSeconds));
        }
    },

    logoutCounterCountdown: function() {
        if (rmgUtils.hasStorage()) {
            if (localStorage.rmgLogoutCounterReset && localStorage.rmgLogoutCounterReset != this.lastCounterReset) {
                this.lastCounterReset = localStorage.rmgLogoutCounterReset;
                this.logoutCounterReset(false);
                return;
            }
        }
        var $counter = this.$headerCounterTimeLeft;
        if (rmgUtils.hasStorage()) {
            var now = new Date().getTime();

            var newTime = window._logoutTimeoutSeconds - Math.floor((now - localStorage.rmgLogoutCounterReset) / 1000);

            var newTime = newTime < 0 ? 0 : newTime;

        } else {
            var curTime = parseInt($counter.attr('data-seconds')),
                newTime = curTime - 1;
        }
        var newText = formatLogoutTime(newTime);

        $counter.attr('data-seconds', newTime).text(newText);
        if (newTime <= 60) {
            $counter.addClass('time-left-one-minute');
        }
        if (newTime <= 0) {
            window.clearInterval(this.countdownIntervalId);
            $.ajax({
                dataType: 'json',
                method: 'POST',
                data: $('form#logout-without-redirect-form').serialize(),
                url: '/userportal/logout-without-redirect/'
            });
            new window.bootstrap.Modal(document.getElementById('modal-logged-out')).show();
        }
    },

    modalsVerticalCenter: function($element) {
        /*
         * Bootstrap modal dialogs are out of the box shown a fixed distance from the top of the screen
         * This centers them vertically
         */
        var $modals;
        if ($element && $element.length) {
            $modals = $element;
        } else {
            $modals = $('.modal.modal-vcenter:visible');
        }
        if ($modals && $modals.length) {
            $modals.each(function(i) {
                /* Briefly make (a clone of) the modal visible (user doesn't see it), in order to be able to measure height */
                var $clone = $(this).clone().css('display', 'block').appendTo('body');
                var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
                /* Should the dialog be larger than the viewport (it shouldn't !), avoid negative top margin */
                top = top > 10 ? top : 10;
                $clone.remove();
                $(this).find('.modal-content').css('margin-top', top);
            });
        }
    },

    placeholdersInit: function() {
        if (typeof $.fn.placeholder === 'function') {
            $('input, textarea').placeholder();
        }
    },

    prettyRadioButtons: function() {
        var $radioButtons = $('.radio-pretty');
        if ($radioButtons.length) {
            $radioButtons.each(function() {
                var $wrapper = $(this),
                    $input = $wrapper.find('input[type="radio"]');
                if ($input.is(':checked')) {
                    $wrapper.addClass('checked');
                }
                $wrapper.addClass('prettified');
                $input.click(function (event) {
                    var target = event.target,
                        $target = $(target),
                        $group = $target.closest('.form-group');
                    $group.find('.radio-pretty').removeClass('checked');
                    $target.closest('.radio-pretty').addClass('checked');
                });
            });
        }
    },

    resizeHandler: function() {
        this.viewportDimensionsUpdate();
        this.handleThresholds();
        this.dropdownDirection();
        this.loginVerticalCenter();
        this.modalsVerticalCenter();
        this.stickyFooterSetHeights();
        this.tableFlippedLayout();
    },

    stickyFooterSetHeights: function() {
        var footerHeight = $('div#footer').outerHeight();
        $('div#push').css({'height': footerHeight+'px'})
        $('div#main-wrap').css({'margin-bottom': '-'+footerHeight+"px"})
    },

    tooltipPlacements: function(viewportWidth) {
        var $validationFeedback = $('span.validation-feedback');
        if ($validationFeedback.length) {
            $validationFeedback.each(function() {
                if ($(this).data && $(this).data('bs.tooltip')) {
                    $(this).tooltip('toggle');
                    var theTooltip = $(this).data('bs.tooltip');
                    theTooltip.options.placement = viewportWidth > 1199 ? 'right' : 'top';
                    $(this).tooltip('toggle');
                }
            });
        }
    },

    tableFlippedCalculate: function($table, lineHeight, idx) {
        var longestLabel = 0,
            longestValue = 0,
            overflowCount = 0,
            rowCount = 0,
            thisClassNames = [],
            stylesheetContent = '',
            stylesheetIsApplied = false,
            tableId = $table.attr('id'),
            valueWidths = [],
            width = $table.innerWidth();

        $table.find('tbody tr').first().find('span.flipped-label').each(function() {
            $(this).css({'width': 'auto', 'display': 'inline-block'});
            var length = Math.ceil($(this).outerWidth());
            $(this).css({'width': '', 'display': ''});
            longestLabel = longestLabel > length ? longestLabel : length;
        });
        $table.find('span.value').each(function() {
            rowCount += 1;
            $(this).siblings().css({'width': '100%'});
            $(this).css({'width': 'auto', 'display': 'inline-block', 'padding-left': '0'});
            var length = Math.ceil($(this).outerWidth());
            $(this).css({'width': '', 'display': '', 'padding-left': ''});
            $(this).siblings().css({'width': ''});
            valueWidths.push(length);
            longestValue = longestValue > length ? longestValue : length;
        });

        var spaceLeft = width - (longestLabel + longestValue + 2*lineHeight);
        spaceLeft = spaceLeft > 0 ? spaceLeft : 0;
        _.forEach(valueWidths, function(valueWidth) {
            if ((width - (longestLabel + valueWidth + 2*lineHeight)) < 0) {
                overflowCount += 1;
            }
        });

        if ((spaceLeft > 0 || .4 > (overflowCount / rowCount)) && .45 > ((longestLabel + 2*lineHeight) / width)) {
            var labelWidth = Math.floor(spaceLeft / 2) + longestLabel + 1;

            if (! tableId) {
                tableId = 'table-flip-'+idx;
                $table.attr('id', tableId);
            }

            stylesheetContent = '\
                table#'+tableId+' td span.flipped-label {\
                    width: '+labelWidth+'px;\
                }\
                table#'+tableId+' td span.value {\
                    padding-left: '+(labelWidth + 2*lineHeight)+'px;\
                }';

            stylesheetIsApplied = true;
        }

        if (stylesheetIsApplied) {
            $table.removeClass('collapsed');
        } else {
            $table.addClass('collapsed');
        }

        return stylesheetContent;
    },

    tableFlippedLayout: function() {
        $('style#table-flip-style').remove();

        if (this.$tableFlipped.count) {
            var tables = [];
            if (this.viewportWidth < 1200 && this.$tableFlipped.md.length) {
                tables = tables.concat(this.$tableFlipped.md);
            }
            if (this.viewportWidth < 992 && this.$tableFlipped.sm.length) {
                tables = tables.concat(this.$tableFlipped.sm);
            }
            if (this.viewportWidth < 768 && this.$tableFlipped.xs.length) {
                tables = tables.concat(this.$tableFlipped.xs);
            }
            if (this.viewportWidth < 600 && this.$tableFlipped.xxs.length) {
                tables = tables.concat(this.$tableFlipped.xxs);
            }


            var stylesheetContent = '',
                lineHeight = rmgUtils.getLineHeight();

            _.forEach(tables, function($table, idx) {
                stylesheetContent += this.tableFlippedCalculate($table, lineHeight, idx+1);
            }.bind(this));

            $('<style id="table-flip-style">')
                .prop('type', 'text/css')
                .html(stylesheetContent)
                .appendTo('head');
        }
    },

    tabularDataCalculate: function($tabularDataElements, lineHeight, classNames, indexInit) {
        var idx = indexInit,
            longestLabel = 0,
            longestValue = 0,
            overflowCount = 0,
            rowCount = 0,
            thisClassNames = [],
            stylesheetContent = '',
            stylesheetIsApplied = false,
            valueWidths = [],
            width = $tabularDataElements.first().innerWidth();

        $tabularDataElements.each(function() {
            idx += 1;
            thisClassNames.push('.tabular-data-'+idx);
            $(this).removeClass(classNames).addClass('tabular-data-'+idx)
            $(this).find('span.label').each(function() {
                var length = Math.ceil($(this).outerWidth());
                longestLabel = longestLabel > length ? longestLabel : length;
            });
            $(this).find('span.value').each(function() {
                rowCount += 1;
                var length = Math.ceil($(this).outerWidth());
                valueWidths.push(length);
                longestValue = longestValue > length ? longestValue : length;
            });
        });

        var spaceLeft = width - (longestLabel + longestValue + lineHeight),
            margin = lineHeight;
        spaceLeft = spaceLeft > 0 ? spaceLeft : 0;
        _.forEach(valueWidths, function(valueWidth) {
            if ((width - (longestLabel + valueWidth + margin)) < 0) {
                overflowCount += 1;
            }
        });

        var limitFactor = rowCount > 10 ? 7 : 4;

        if (spaceLeft > 0 || rowCount >= limitFactor * overflowCount) {
            var labelWidth = Math.floor(spaceLeft / 2) + longestLabel;

            stylesheetContent = '\
                '+thisClassNames.join(' .col-label, ')+' .col-label {\
                    width: '+labelWidth+'px;\
                    text-align: right;\
                    float: left;\
                }\
                '+thisClassNames.join(' .col-value, ')+' .col-value {\
                    width: '+(width - (labelWidth + margin))+'px;\
                    margin-left: '+(labelWidth + margin)+'px;\
                }';

            stylesheetIsApplied = true;
        }

        $tabularDataElements.each(function() {
            if (stylesheetIsApplied) {
                $(this).removeClass('collapsed');
            } else {
                $(this).addClass('collapsed');
            }
        });

        return stylesheetContent;
    },

    tabularDataLayout: function() {
        $('style#tabular-data-style').remove();

        if (this.$tabularData.count) {
            var stylesheetContent = '\
                    .tabular-data {\
                        transition: opacity .3s ease;\
                        -webkit-transition: opacity .3s ease;\
                        -moz-transition: opacity .3s ease;\
                        -ms-transition: opacity .3s ease;\
                        -o-transition: opacity .3s ease;\
                }',
                idx = 0,
                classNames = '',
                lineHeight = rmgUtils.getLineHeight();

            _.forEach(_.range(1, this.$tabularData.count), function(i) {
                classNames += 'tabular-data-' + i + (i === this.$tabularData.count ? 'this.$tabularData.count' : ' ');
            }.bind(this));

            _.forEach(this.$tabularData.single, function($tabularDataElements, i) {
                stylesheetContent += this.tabularDataCalculate($tabularDataElements, lineHeight, classNames, idx);
                idx = i;
            }.bind(this));
            _.forEach(this.$tabularData.grouped, function($tabularDataElements) {
                var areUnderneathEachOther = true;
                $tabularDataElements.each(function(i, element) {
                    if (areUnderneathEachOther) {
                        areUnderneathEachOther = $(element).closest('div[class^="col-"]').outerWidth() === $(element).closest('div.row').outerWidth();
                    }
                });
                if (areUnderneathEachOther) {
                    stylesheetContent += this.tabularDataCalculate($tabularDataElements, lineHeight, classNames, idx);
                    idx += $tabularDataElements.length;
                } else {
                    $tabularDataElements.each(function(i, element) {
                        stylesheetContent += this.tabularDataCalculate($(element), lineHeight, classNames, idx);
                        idx += 1;
                    }.bind(this));
                }
            }.bind(this));

            $('<style id="tabular-data-style">')
                .prop('type', 'text/css')
                .html(stylesheetContent)
                .appendTo('head');

            if (! this.$tabularData.all[0].first().hasClass('js-shown')) {
                setTimeout(
                    this.tabularDataShow.bind(this),
                    100
                );
            }
        }
    },

    tabularDataShow: function() {
        _.forEach(this.$tabularData.all, function($tabularDataElements) {
            $tabularDataElements.each(function() {
                $(this).addClass('js-shown');
            });
        }.bind(this))
    },

    touchIsTapUnset: function() {
        this.touchIsTap = false;
    },

    touchIsTapSet: function() {
        this.touchIsTap = true;
        window.setTimeout(this.touchIsTapUnset.bind(this), 500);
    },

    viewportDimensionsUpdate: function() {
        this.viewportHeight = rmgUtils.getViewportHeight();
        this.viewportWidth = rmgUtils.getViewportWidth();
    }
};

window.rmgMainApp = rmgMainApp;
