var rmgUtils = {
	$lineHeightHelper: null,

	capitalizeNameOnCard: function(str) {
		return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
	},

	classNames: function() {
		var classes = [];

		for (var i = 0; i < arguments.length; i++) {
			var arg = arguments[i];
			if (!arg) continue;

			var argType = typeof arg;

			if (argType === 'string' || argType === 'number') {
				classes.push(arg);
			} else if (Array.isArray(arg)) {
				classes.push(classNames.apply(null, arg));
			} else if (argType === 'object') {
				for (var key in arg) {
					if (arg.hasOwnProperty(key) && arg[key]) {
						classes.push(key);
					}
				}
			}
		}

		return classes.join(' ');
	},

	getLineHeight: function() {
		if (! this.$lineHeightHelper) {
            this.$lineHeightHelper = $('p#line-height-helper');
        }
		var lineHeight = this.$lineHeightHelper.css('line-height');
		if (lineHeight) {
			return parseInt(lineHeight.replace('px', ''));
		} else {
			return 16;
		}
	},

	getTouchCoordinates: function(event, axis) {
		return /touch/.test(event.type) ? (event.originalEvent || event).changedTouches[0]['page'+axis] : event['page'+axis];
	},

	getViewportHeight: function() {
		var e = window, a = 'inner';
		if (!('innerHeight' in window )) {
			a = 'client';
			e = document.documentElement || document.body;
		}
		return e[ a+'Height' ];
	},

	getViewportWidth: function() {
		var e = window, a = 'inner';
		if (!('innerWidth' in window )) {
			a = 'client';
			e = document.documentElement || document.body;
		}
		return e[ a+'Width' ];
	},

	hasStorage: function() {
        var uid = new Date,
			result;

        try {
            localStorage.setItem(uid, uid);
            result = localStorage.getItem(uid) == uid;
            localStorage.removeItem(uid);
            return result && localStorage;
        } catch (exception) {}

		return false;
    },

	mediatorLookup: function(formName, mediatorName, pageDict) {
		if (pageDict.hasOwnProperty(formName)) {
			var form = pageDict[formName];

			if (form.hasOwnProperty(mediatorName)) {
				return form[mediatorName];
			} else {
				for (var key in form) {
					if (form.hasOwnProperty(key) && key.split('.')[0] === mediatorName) {
						return form[key];
					}
				}
				return {};
			}
		}
		return {};
	},

	pad2: function(number) {
		return (number < 10 ? '0' : '') + number;
	},

	processTimeEstimationText: function(operationsRemaining, hasStarted) {
		var estimatedTimeMessage;
        if (operationsRemaining < 23) {
			if (hasStarted) {
                estimatedTimeMessage = i18n.gettext("This process will be done in <strong>less than a minute</strong>");
            } else {
                estimatedTimeMessage = i18n.gettext("This process will need <strong>less than a minute</strong> to run.");
            }
        } else if (operationsRemaining < 47) {
			if (hasStarted) {
                estimatedTimeMessage = i18n.gettext("This process will be done in <strong>around a minute</strong>");
            } else {
                estimatedTimeMessage = i18n.gettext("This process will need <strong>around a minute</strong> to run.");
            }
        } else {
            var estimatedTimeMinutes = Math.floor(operationsRemaining * 2.5 / 60);
            if (estimatedTimeMinutes < 60) {
				if (hasStarted) {
					estimatedTimeMessage = i18n.gettext("This process will be done in approximately <strong>{minutes} minutes</strong>")
						.replace('{minutes}', estimatedTimeMinutes);
				} else {
					estimatedTimeMessage = i18n.gettext("This process will need approximately <strong>{minutes} minutes</strong> to run.")
						.replace('{minutes}', estimatedTimeMinutes);
				}
            } else {
				if (hasStarted) {
					estimatedTimeMessage = i18n.gettext("This process will be done in approximately <strong>{minutes} minutes</strong>")
						.replace('{minutes}', estimatedTimeMinutes);
				} else {
					estimatedTimeMessage = i18n.gettext("This process will need approximately <strong>{duration} hours</strong> to run.")
                        .replace('{duration}', Math.floor(estimatedTimeMinutes/60)+':'+this.pad2(estimatedTimeMinutes % 60));
				}
            }
        }

		return estimatedTimeMessage;
	},

	transitionEnd: function() {
		var element = document.createElement('div'),
			transEndEventNames = {
			WebkitTransition : 'webkitTransitionEnd',
			MozTransition    : 'transitionend',
			OTransition      : 'oTransitionEnd otransitionend',
			transition       : 'transitionend'
		}

		for (var name in transEndEventNames) {
			if (element.style[name] !== undefined) {
				return transEndEventNames[name];
			}
		}

		return false;
	}
}

window.rmgUtils = rmgUtils;
